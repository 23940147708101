import React, { useState, useEffect } from "react";
import logo from '../logo.jpeg';
const CountdownTimer = () => {
    const [timeRemaining, setTimeRemaining] = useState(0);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            const currentTime = new Date().getTime();
            const eventTime = new Date('2024-12-30').getTime();
            let remainingTime = eventTime - currentTime;
            if (remainingTime <= 0) {
                remainingTime = 0;
                clearInterval(countdownInterval);
                alert("Countdown complete!");
            }

            setTimeRemaining(remainingTime);
        }, 1000);

        return () => clearInterval(countdownInterval);
        // }
    }, [timeRemaining]);
    const formatDay = (time) => {
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        return days.toString().padStart(2, "0")
    };
    const formatHours = (time) => {
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        return hours.toString().padStart(2, "0")
    };
    const formatMinutes = (time) => {
        const minutes = Math.floor((time / (1000 * 60)) % 60)
        return minutes.toString().padStart(2, "0")
    };
    const formatSecond = (time) => {
        const seconds = Math.floor((time / 1000) % 60);
        return seconds.toString().padStart(2, "0");
    };

    return (
        <div class="countdown-container">
            <img src={logo} alt="logo" style={{ width: '30%' }} />
            <h1>WE'RE LAUNCHING SOON</h1>
            <p>We are providing vastu services that helps people to live healthy and wealthy life</p>
            <div class="countdown">
                <div class="countdown-box">
                    <span class="countdown-time" id="days">{formatDay(timeRemaining)}</span>
                    <span class="countdown-label">DAYS</span>
                </div>
                <div class="countdown-box">
                    <span class="countdown-time" id="hours">{formatHours(timeRemaining)}</span>
                    <span class="countdown-label">HOURS</span>
                </div>
                <div class="countdown-box">
                    <span class="countdown-time" id="minutes">{formatMinutes(timeRemaining)}</span>
                    <span class="countdown-label">MINUTES</span>
                </div>
                <div class="countdown-box">
                    <span class="countdown-time" id="seconds">{formatSecond(timeRemaining)}</span>
                    <span class="countdown-label">SECONDS</span>
                </div>
            </div>
            <div class="social-links">
                <a href="#"><i class="fab fa-facebook-f"></i></a>
                {/* <a href="#"><i class="fab fa-pinterest"></i></a> */}
                <a href="#"><i class="fab fa-instagram"></i></a>
                <a href="#"><i class="fab fa-twitter"></i></a>
            </div>
        </div>
    );
};

export default CountdownTimer;
// import logo from './logo.svg';
import { useEffect } from 'react';
import './App.css';
import CountdownTimer from "./components/CountDown";
function App() {

  useEffect(() => {
    const background = document.querySelector('.App');

    // Generate random dots
    for (let i = 0; i < 100; i++) {
      const dot = document.createElement('div');
      dot.classList.add('dot');
      dot.style.left = Math.random() * 100 + 'vw';
      dot.style.top = Math.random() * 100 + 'vh';
      dot.style.animationDuration = Math.random() * 5 + 5 + 's'; // Between 5 and 10 seconds
      dot.style.animationDelay = Math.random() * 5 + 's';
      background.appendChild(dot);
    }
  }, [])
  return (
    <div className="App">
      <CountdownTimer />
    </div>
  );
}

export default App;
